import logo from "./assets/intermedia_logo.svg";
import instagram from "./assets/instagram_logo.svg";
import facebook from "./assets/facebook_logo.svg";
import twitter from "./assets/twitter_logo.svg";
import tiktok from "./assets/tiktok_logo.svg";
import youtube from "./assets/youtube_logo.svg";
import linkedin from "./assets/linkedin_logo.svg";
import mail from "./assets/mail_icon.svg";
import whatsapp from "./assets/whatsapp_logo.svg";
import pointer from "./assets/pointer_icon.svg";
import santi from "./assets/santi_profile.png";
import mexico from "./assets/mexicoflag.png";
import argentina from "./assets/argentinaflag.svg";
import "./App.css";
import HubspotForm from "react-hubspot-form";
import { useRef } from "react";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function App() {
  const formRef = useRef(null);
  return (
    <>
      <div className="app-container">
        <div className="h-full w-full flex justify-center">
          <div className="max-w-7xl h-full w-full px-8 md:px-0">
            <div className="flex justify-center">
              <a href="https://intermediait.com/" target="_blank" rel="noreferrer">
                <img src={logo} alt="Logo Intermedia" />
              </a>
            </div>
            <div className="">
              <span className="font-manrope md:text-4xl text-sm font-light text-white md:px-0 px-4 md:py-0 py-1 md:border-0 border rounded-2xl">
                WEBINAR
              </span>
            </div>
            <div className="md:flex md:mt-12 mt-4">
              <div className="w-full">
                <h1 className="font-dosis font-extrabold md:text-5xl text-xl text-white">
                  ¿Nativo o Híbrido?
                </h1>
                <h2 className="font-dosis font-medium md:text-4xl text-lg text-white mt-4">
                  Todo lo que debes saber para desarrollar una app móvil en 2022
                </h2>
                <div className="md:w-24 w-12 h-1 mt-8 bg-custom-orange rounded-xl mb-6"></div>
                <span className="font-manrope md:text-xl text-sm font-bold text-white flex">
                  22 de Septiembre | 2022
                </span>
                <div className="mt-6">
                  <div className="flex flex-row items-center">
                    <img src={mexico} alt="Bandera de Mexico" />
                    <div className="bg-spblack p-3 rounded-lg"><span className="font-manrope text-white font-normal px-2">18hs</span></div>
                    <img src={argentina} className="pl-6" alt="Bandera de Argentina" />
                    <div className="bg-spblack p-3 rounded-lg"><span className="font-manrope text-white font-normal px-2">20hs</span></div>
                  </div>
                </div>
                <span className="font-manrope text-white font-normal md:text-2xl text-base mt-12 flex">
                  Te invitamos a nuestro próximo webinar en donde te
                  proporcionaremos un análisis general y argumentado, que te
                  sirva de guía para la toma de decisiones al iniciar un
                  proyecto de desarrollo de una aplicación móvil.
                </span>
                <span className="font-dosis text-white font-bold md:text-3xl text-lg mt-4 flex">
                  ¡No te quedes sin tu lugar!
                </span>
              </div>
              <div className="w-full" ref={formRef}>
                <div className="mt-4 md:mt-0 md:mx-14 md:px-16 px-8 md:pt-12 pt-8 bg-white rounded-3xl">
                  <HubspotForm
                    portalId="20468449"
                    formId="bdafdb38-f259-4623-ab6d-19531c5f3ca4"
                    onSubmit={() => { }}
                    onReady={(form) => { }}
                    loading={<div>Loading</div>}
                  />
                </div>
              </div>
            </div>
            <div className="md:mb-20 mb-8">
              <span className="font-dosis text-white font-bold md:text-3xl text-lg md:mt-14 mt-4 flex">
                Se hablará sobre:
              </span>
              <span className="font-manrope text-white font-normal md:text-2xl text-sm md:mt-12 mt-4 flex">
                👉 Plataformas nativas.
              </span>
              <span className="font-manrope text-white font-normal md:text-2xl text-sm mt-2 flex">
                👉 Plataformas híbridas (Flutter vs. React Native).
              </span>
              <span className="font-manrope text-white font-normal md:text-2xl text-sm mt-2 flex">
                👉 Pros y contras de las distintas plataformas.
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-12 bg-white/30">
          <div className="w-full flex justify-end relative">
            <img
              src={santi}
              alt="Foto Santiago Carliski"
              className="absolute md:-top-36 -top-4 md:h-96 h-56"
            />
          </div>
          <div className="w-full flex">
            <div className="hidden md:flex">
              <span className="flex mt-6 mr-2 text-5xl">🗣</span>
            </div>
            <div className="flex flex-col">
              <span className="mt-8 font-manrope font-bold md:text-4xl text-xl text-custom-green">
                SPEAKER
              </span>
              <div className="md:w-24 w-12 h-1 mt-4 bg-custom-green rounded-xl mb-6"></div>
              <span className="font-manrope font-bold md:text-3xl text-lg text-white">
                Santiago Carliski
              </span>
              <span className="font-manrope md:text-xl text-sm text-custom-grey">CEO of Intermedia</span>
              <span className="mb-8 font-manrope md:text-xl text-sm text-custom-grey">
                Software developer expert
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button className="md:my-28 mb-12 bg-custom-orange px-16 md:px-44 md:py-4 py-3 rounded-lg md:text-3xl text-white font-manrope font-extrabold" onClick={() => {
            scrollToRef(formRef);
          }}>
            ¡Quiero participar!
          </button>
        </div>
      </div>
      <div className="h-full w-full flex justify-center bg-dark-blue">
        <div className="max-w-7xl w-full px-8 md:px-0">
          <div className="md:flex justify-around">
            <div className="w-full md:pt-8 pt-3 md:pr-32">
              <div className="hidden md:flex justify-center md:w-2/3 md:pl-20">
                <img src={logo} alt="Logo Intermedia" />
              </div>
              <span className="font-manrope text-white font-normal text-sm md:pl-28 mt-4 flex">
                ¡Seguinos en nuestras redes sociales para enterarte de todas
                nuestras novedades!
              </span>
              <div className="flex md:pl-28 mt-4">
                <div className="bg-white p-3 rounded-full mr-3">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/intermediait/" rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
                <div className="bg-white p-3 rounded-full mr-3">
                  <a
                    target="_blank"
                    href="https://es-la.facebook.com/intermediaintlabs/" rel="noreferrer"
                  >
                    <img src={facebook} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
                <div className="bg-white p-3 rounded-full mr-3">
                  <a target="_blank" href="https://twitter.com/intermediait1/" rel="noreferrer">
                    <img src={twitter} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
                <div className="bg-white p-3 rounded-full mr-3">
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@intermedia.it?lang=en" rel="noreferrer"
                  >
                    <img src={tiktok} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
                <div className="bg-white p-3 rounded-full mr-3">
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCoevd_jijHmX6QW872AumfA" rel="noreferrer"
                  >
                    <img src={youtube} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
                <div className="bg-white p-3 rounded-full mr-3">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/intermedia-interactive-labs" rel="noreferrer"
                  >
                    <img src={linkedin} alt="Instagram" className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-white my-12 w-1"></div>
            <div className="w-full md:pt-8 md:px-28 md:pb-8 pb-2">
              <span className="font-manrope text-white font-bold text-2xl md:text-3xl">
                Contactanos
              </span>
              <div className="md:mt-10 mt-4 mb-12">
                <div className="flex items-center">
                  <img src={mail} alt="Instagram" className="w-4 h-4 mr-3" />
                  <span className="font-manrope text-white font-normal text-xs md:text-sm flex">
                    guillermo@intermediait.com
                  </span>
                </div>
                <div className="flex items-center">
                  <img
                    src={whatsapp}
                    alt="Instagram"
                    className="w-4 h-4 mr-3"
                  />
                  <span className="font-manrope text-white font-normal text-xs md:text-sm flex my-1">
                    +5491137624561
                  </span>
                </div>
                <div className="flex items-center">
                  <img src={pointer} alt="Instagram" className="w-4 h-4 mr-3" />
                  <span className="font-manrope text-white font-normal text-xs md:text-sm flex">
                    664 Belgrano, Tandil (7000), Pcia. de Buenos Aires,
                    Argentina.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
